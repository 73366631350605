.header{
    display: flex;
    justify-content: space-between;
}

.logo{
    width: 10rem;
    height: 3rem;
}

.header-menu{
    list-style-type: none;
    display: flex;
    gap: 2rem;
    color: white;
}



.header-menu>li:hover{
    color: var(--orange);
    cursor: pointer;
}

.bars-container{
    width: 2rem;
    height: 2rem;
    background-color: var(--appColor);
    padding: 0.4rem;
    border-radius: 6px;
}


.bars{
    width: 100%;
    height: 100%;
    cursor: pointer;
    
}


@media screen and (max-width:768px) {

    .header>:nth-child(2){
        position: fixed;
        right: 2rem;
        z-index: 99;
    }

    .header-menu{
        flex-direction: column;
        background-color: var(--appColor);
        padding: 1rem;
    }
    
}