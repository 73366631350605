.footer-container{
    position: relative;
}

.footer-container>hr{
    border: 1px solid var(--lightgray);
}

.footer{
    padding: 1rem 2rem;
    display: flex;
    flex-direction: column;
    gap: 4rem;
    justify-content: center;
    align-items: center;
    height: 20rem;
}

.socials{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 3rem;
}

.socials>img{
    width: 2rem;
    height: 2rem;
    padding:0 0.8rem ;
}

