.join{
    display: flex;
    padding: 0 2rem;
    gap: 10rem;
    /* justify-content: space-around; */
}

.left-j{
    color: wheat;
    font-size: 3rem;
    font-weight: bold;
    text-transform: uppercase;
    position: relative;
}

.left-j>hr{
    border: 2px solid var(--orange);
    width: 8rem;
    position: absolute;
    margin: -10px 0;
    /* position: relative;
    /* right: 12rem;
    top: 1rem; */ 
}

.right-j{
    display: flex;
    justify-content: center;
    align-items: flex-end;
}

.email-container{
    display: flex;
    gap: 3rem;
    background-color: gray;
    padding: 1rem;
    border-radius: 10px;
}

.email-container>input{
    background-color: transparent;
    border: none;
    outline: none;
    color: var(--lightgray);
    color: white;
}

::placeholder{
    color: white;
}

.btn-j{
    background-color: var(--orange);
    color: white;
}


@media screen and (max-width:768px) {

    .join{
        flex-direction: column;
        gap: 1rem;
    }

    
    
}